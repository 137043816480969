import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@material-ui/lab/Skeleton";
import ApiUrl from "../../services/ApiUrl";

export default function SessionList() {
  const [sessionList, setSessionList] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [inputSessionID, setInputSessionID] = useState("");
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [formText, setFormText] = useState(
    "Enter a session ID and hit find to find a specific session."
  );
  const history = useHistory();

  useEffect(() => {
    fetch(`${ApiUrl.api}session/`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((res) =>
        res.Items.sort((a: { Timestamp: number }, b: { Timestamp: number }) => {
          if (a.Timestamp > b.Timestamp) return -1;
          if (b.Timestamp > a.Timestamp) return 1;
          return 0;
        })
      )
      .then((response) => {
        setSessionList(response);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [setSessionList, setLoading]);

  const handleClick = (id: string) => {
    history.push(`/admin/${id}`);
  };

  const handleInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setInputSessionID(event.target.value);
  };

  const handleChangePage = (
    _event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFormSubmit = () => {
    if (inputSessionID && inputSessionID.length === 30) {
      handleClick(inputSessionID);
    } else {
      setFormText("Please enter a valid Session ID");
      setError(true);
    }
  };

  const handleTextFieldKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) handleFormSubmit();
  };

  return (
    <div>
      <Typography variant="h2" align="center" component="h1">
        <strong>Sessions</strong>
      </Typography>
      <br />
      <Divider />
      <br />
      <Typography variant="h4" align="center" component="h1">
        <strong>Find By ID</strong>
      </Typography>
      <Box sx={{ textAlign: "center" }}>
        <FormControl>
          <TextField
            error={error}
            onKeyDown={handleTextFieldKeyDown}
            id="standard-required"
            label="Session ID"
            helperText={formText}
            onChange={handleInputChange}
            value={inputSessionID}
          />
          <Button
            onClick={handleFormSubmit}
            variant="contained"
            color="primary"
          >
            Find Session
          </Button>
        </FormControl>
      </Box>
      <br />
      <Divider />
      <br />
      <Typography variant="h4" align="center" component="h1">
        <strong>Session List</strong>
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Session ID</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Client IP</TableCell>
              <TableCell>POP ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <Fragment>
                {[...Array(4)].map((i, m) => (
                  <TableRow key={`row-${m}`}>
                    {[...Array(4)].map((i, n) => (
                      <TableCell key={`cell-${n}`}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </Fragment>
            ) : (
              <Fragment>
                {sessionList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((session) => (
                    <TableRow
                      hover
                      onClick={() => {
                        handleClick(session.SessionID);
                      }}
                      key={session.SessionID}
                    >
                      <TableCell component="th" scope="row">
                        {session.SessionID}
                      </TableCell>
                      <TableCell>{session.Timestamp}</TableCell>
                      <TableCell>{session.clientIP}</TableCell>
                      <TableCell>{session.POPID}</TableCell>
                    </TableRow>
                  ))}
              </Fragment>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sessionList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
