import { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ApiUrl from '../../../services/ApiUrl';

export type tProps = {
    clientIP: string;
    hasCookies: boolean;
    HTTPVersion: string;
    popID: string;
    sessionID: string;
    sessionStarted: boolean;
    time: string;
    userAgent: string;
};

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: 'gray',
            color: theme.palette.common.white,
            fontSize: 24,
        },
    })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const BrowserInfo = (props: tProps): JSX.Element => {
    const {
        clientIP,
        hasCookies,
        HTTPVersion,
        popID,
        sessionID,
        sessionStarted,
        time,
        userAgent,
    } = props;

    const dataElements = [
        {
            label: 'POP ID',
            value: popID,
        },
        {
            label: 'Timestamp',
            value: time,
        },
        {
            label: 'User Agent',
            value: userAgent,
        },
        {
            label: 'Cookies',
            value: `${hasCookies ? 'Yes' : 'No'}`,
        },
        {
            label: 'HTTP Version',
            value: HTTPVersion,
        },
        {
            label: 'Client ID',
            value: clientIP,
        },
    ];

    return (
        <Fragment>
            {sessionStarted ? (
                <Box my={4}>
                    <Typography variant="h4" align="center" component="h1">
                        <strong>Session ID:</strong>
                    </Typography>
                    <Typography variant="h4" align="center" component="h1">
                        {sessionID}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        align="center"
                        component="p"
                        gutterBottom
                    >
                        Please provide this session ID to your customer support
                        representative.
                    </Typography>
                    <Divider />
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell
                                        component="th"
                                        scope="row"
                                        colSpan={2}
                                    >
                                        Session Details:
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataElements.map((row, index) => {
                                    return row.value ? (
                                        <StyledTableRow key={index}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {row.label}
                                            </TableCell>
                                            <TableCell>{row.value}</TableCell>
                                        </StyledTableRow>
                                    ) : null;
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {ApiUrl.dnsWildcard && sessionID ? (
                        <img
                            alt="DNS Test"
                            src={`https://${sessionID}.${ApiUrl.dnsWildcard}/image.jpg`}
                        />
                    ) : (
                        ''
                    )}
                </Box>
            ) : (
                <Typography align="center">
                    <CircularProgress size={150} color="primary" />
                </Typography>
            )}
        </Fragment>
    );
};

export default BrowserInfo;
