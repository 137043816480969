import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ApiUrl from '../../../services/ApiUrl';
import { CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import SessionDetailTable from '../SessionDetail/SessionDetailTable';
import './SessionDetailContainer.scss';

export type tParams = {
    id: string;
};

const SessionDetailContainer = (): JSX.Element => {
    const { id } = useParams<tParams>();
    const [hasCookies, setHasCookies] = useState(false);
    const [userAgent, setUserAgent] = useState('');
    const [time, setTime] = useState('');
    const [sesstionStarted, setSesstionStarted] = useState<boolean>(false);
    const [popID, setPopID] = useState('');
    const [HTTPVersion, setHTTPVersion] = useState('');
    const [clientIP, setClientIP] = useState('');
    const [dnsResolver, setDnsResolver] = useState<string>('');
    const [sessionID, setSessionID] = useState('');
    const [notFound, setNotFound] = useState(false);
    const [loop, setLoop] = useState<ReturnType<typeof setInterval> | null>(
        null
    );

    useEffect(() => {
        const loadData = async () => {
            try {
                const res = await fetch(`${ApiUrl.api}session/${id}`, {
                    method: 'GET',
                    headers: new Headers({
                        Accept: 'application/json',
                    }),
                });
                const response = await res.json();
                const firstEl = response[0];
                if (firstEl) {
                    setUserAgent(firstEl.UserAgent);
                    setTime(firstEl.Timestamp);
                    setClientIP(firstEl.clientIP);
                    setHTTPVersion(firstEl.HTTPVersion);
                    setSessionID(firstEl.SessionID);
                    setHasCookies(firstEl.Cookies);
                    setPopID(firstEl.POPID);
                    setSesstionStarted(true);
                    if (firstEl.dnsresolver) {
                        setDnsResolver(firstEl.dnsresolver);
                        loop && clearInterval(loop);
                    } else {
                        setDnsResolver('Loading...');
                    }
                } else {
                    setNotFound(true);
                }
            } catch (error) {
                console.log(error);
            }
        };

        loadData();
        const timer: ReturnType<typeof setInterval> = setInterval(() => {
            loadData();
        }, 30000);
        setLoop(timer);
        return () => {
            loop && clearInterval(loop);
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {!sessionID && (
                <Typography align="center">
                    <CircularProgress size={150} color="primary" />
                </Typography>
            )}
            {sessionID && (
                <SessionDetailTable
                    clientIP={clientIP}
                    cookiesEnabled={hasCookies}
                    dnsResolver={dnsResolver}
                    HTTPVersion={HTTPVersion}
                    notFound={notFound}
                    popID={popID}
                    sessionID={sessionID}
                    sessionStarted={sesstionStarted}
                    timeStamp={time}
                    userAgent={userAgent}
                />
            )}
        </div>
    );
};

export default SessionDetailContainer;
