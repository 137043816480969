import { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { CircularProgress } from '@material-ui/core';
import './SessionDetailTable.scss';

export type tProps = {
    clientIP: string;
    cookiesEnabled: boolean;
    dnsResolver?: string;
    HTTPVersion: string;
    notFound: boolean;
    popID: string;
    sessionID: string;
    sessionStarted: boolean;
    timeStamp: string;
    userAgent: string;
};

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: 'gray',
            color: theme.palette.common.white,
            fontSize: 24,
        },
    })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const SessionDetailTable = (props: tProps): JSX.Element => {
    const {
        clientIP,
        cookiesEnabled,
        dnsResolver,
        HTTPVersion,
        notFound,
        popID,
        sessionID,
        sessionStarted,
        timeStamp,
        userAgent,
    } = props;

    const dataElements = [
        {
            label: 'POP ID',
            value: popID,
        },
        {
            label: 'Timestamp',
            value: timeStamp,
        },
        {
            label: 'User Agent',
            value: userAgent,
        },
        {
            label: 'Cookies',
            value: `${cookiesEnabled ? 'Yes' : 'No'}`,
        },
        {
            label: 'HTTP Version',
            value: HTTPVersion,
        },
        {
            label: 'Client ID',
            value: clientIP,
        },
        {
            label: 'DNS Resolver IP',
            value: dnsResolver,
        },
    ];

    return (
        <Fragment>
            <Button color="primary" component={RouterLink} to="/admin">
                Back to Session List
            </Button>
            {sessionStarted ? (
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell component="th" scope="row">
                                        Session ID:
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {sessionID}
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataElements.map((row, index) => {
                                    return row.value ? (
                                        <StyledTableRow key={index}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {row.label}
                                            </TableCell>
                                            <TableCell>{row.value}</TableCell>
                                        </StyledTableRow>
                                    ) : null;
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <Typography align="center">
                    {notFound ? (
                        'Session ID Not Found'
                    ) : (
                        <Typography align="center">
                            <CircularProgress size={150} color="primary" />
                        </Typography>
                    )}
                </Typography>
            )}
        </Fragment>
    );
};

export default SessionDetailTable;
