import Container from '@material-ui/core/Container';
import TroubleShootTool from './client/TroubleShootTool'
import SessionDetailContainer from './admin/SessionDetailContainer/SessionDetailContainer'
import SessionList from './admin/SessionList'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

export default function App() {
  return (
    <Router>
      <Container maxWidth="md">
        <Switch>
          <Route exact path="/">
            <TroubleShootTool />
          </Route>
          <Route exact path="/admin" component={SessionList} />
          <Route exact path="/admin/:id" component={SessionDetailContainer} />
        </Switch>
      </Container>
    </Router>
  );
}
