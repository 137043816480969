import { useState, useEffect } from 'react';
import BrowserInfo from '../BrowserInfo/BrowserInfo';
import ApiUrl from '../../../services/ApiUrl';

export type tProps = {};

const BrowserInfoContainer = (props: tProps): JSX.Element => {
    const [hasCookies] = useState(!!window.navigator.cookieEnabled);
    const [userAgent, setUserAgent] = useState('');
    const [time, setTime] = useState('');
    const [sessionStarted, setSessionStarted] = useState(false);
    const [popID, setPopID] = useState<string>('');
    const [HTTPVersion, setHTTPVersion] = useState('');
    const [clientIP, setClientIP] = useState('');
    const [sessionID, setSessionID] = useState('');

    useEffect(() => {
        fetch(`/egde-check.json`, {
            method: 'GET',
            headers: new Headers({
                Accept: 'application/json',
            }),
        })
            .then((response) => {
                setPopID(
                    response.headers.get('x-amz-cf-pop') || 'no-edge-found'
                );
            })
            .catch((error) => console.log(error));
    }, [setPopID]);

    useEffect(() => {
        if (!popID) return;

        fetch(`${ApiUrl.api}session`, {
            method: 'PUT',
            headers: new Headers({
                Accept: 'application/json',
            }),
            body: JSON.stringify({
                POPID: popID,
                Cookies: hasCookies,
            }),
        })
            .then((res) => res.json())
            .then((response) => {
                setUserAgent(response.userAgent);
                setTime(response.Timestamp);
                setClientIP(response.clientIP);
                setHTTPVersion(response.HTTPVersion);
                setSessionID(response.SessionID);
                setSessionStarted(true);
            })
            .catch((error) => console.log(error));
    }, [
        popID,
        hasCookies,
        setUserAgent,
        setTime,
        setSessionStarted,
        setHTTPVersion,
        setClientIP,
        setSessionID,
    ]);

    return (
        <div>
            <BrowserInfo
                clientIP={clientIP}
                hasCookies={hasCookies}
                HTTPVersion={HTTPVersion}
                popID={popID}
                sessionID={sessionID}
                sessionStarted={sessionStarted}
                time={time}
                userAgent={userAgent}
            />
        </div>
    );
};

export default BrowserInfoContainer;
